import React, { useCallback, useState, useEffect, useContext } from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { FETCH_INVOICE, DOWNLOAD_SINGLE_INVOICE } from 'actions/invoice';
import { useDispatch } from 'react-redux';
import { BiSort } from 'react-icons/bi';
import PropTypes from 'prop-types';
import Card from 'components/inputs/Card';
import CZLogo from 'assets/images/icons/czlogo.png';
import Button from 'components/inputs/Button';
import { GET_CHARGER_BOOKING } from 'actions/chargerBooking';
import { useParams } from 'react-router-dom';
import { roundOfDigit } from 'components/common/utils';
import { Spinner } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { NavContext } from 'components/privateRoute/PrivateRoute';
import { formatNumber } from 'components/common/utils';

const InvoiceInfo = (props) => {
  const { startingDate, endingDate } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isAllowed } = useContext(NavContext);
  const [sortByItem, setSortByItem] = useState({ item: '', order: '' });
  const invoiceList = useSelector((state) => state.invoice.invoices);
  const isLoader = useSelector((state) => state.invoice.isLoading);
  const page = useSelector((state) => state.invoice.page);
  const limit = useSelector((state) => state.invoice.limit);
  const [downloading, setDownloading] = useState({});

  const singleInvoiceDownload = (item) => {
    const chargerBookingId = item._id ? item._id : item.id;
    setDownloading((prev) => ({
      ...prev,
      [chargerBookingId]: true,
    }));
    dispatch({
      type: DOWNLOAD_SINGLE_INVOICE,
      payload: chargerBookingId,
      cb: (res) => {
        if (res) {
          if (_.get(res, 'status') === 200) {
            fileDownload(res.data, `${'Invoice'}.pdf`);
          }
        }
        setDownloading(false);
        setDownloading(false);
      },
    });
  };

  const getInvoice = useCallback((data = {}) => {
    dispatch({ type: FETCH_INVOICE, payload: data });
  }, []);

  useEffect(() => {
    if (sortByItem.item && sortByItem.order) {
      const filterDateData = {
        from: moment(startingDate).utc(),
        to: moment(endingDate).utc(),
      };
      getSortByItem(sortByItem.item, sortByItem.order, '', filterDateData);
    }
  }, [sortByItem]);

  const getSortByItem = (name, order, page, filterDateData) => {
    const sortedOrder = order || 'asc';
    if (page && startingDate && endingDate) {
      const data = {
        from: moment(startingDate).utc(),
        to: moment(endingDate).utc(),
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      endingDate && getInvoice(data);
    } else if (page) {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
        page: page.selected + 1,
      };
      getInvoice(data);
    } else if (filterDateData) {
      const data = {
        ...filterDateData,
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      endingDate && getInvoice(data);
    } else {
      const data = {
        sortBy: `${sortedOrder === 'asc' ? '' : '-'}${name}`,
      };
      getInvoice(data);
    }
  };

  const handleSorting = (sortItem, dataItem) => {
    if (dataItem.item === sortItem) {
      const sortOrder = dataItem.order === 'asc' ? 'desc' : 'asc';
      setSortByItem({ item: sortItem, order: sortOrder });
      getSortByItem(sortItem, sortOrder);
    } else {
      setSortByItem({ item: sortItem, order: 'asc' });
      getSortByItem(sortItem, 'asc');
    }
  };

  return (
    <div className="invoice-list--table">
      <SkeletonTheme baseColor="#1b2a38" highlightColor="#15212b" enableAnimation="true">
        <div className="table-responsive">
          <table className="record-list-table">
            <thead>
              <tr>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.srNo')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.customerName')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.customerNumber')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.email')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.ourGSTNumber')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.state')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.gstNoCustomer')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.invoiceNumber')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.invoiceDate')}</span>
                  </div>
                </th>

                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.unitConsumed')}</span>
                    <span className="ico" onClick={() => handleSorting('meterstart', sortByItem)}>
                      <BiSort size={15} />
                    </span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.serviceCharge')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.taxableValue')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.cgst')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.sgst')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.igst')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.discount')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.cashback')}</span>
                  </div>
                </th>
                <th>
                  <div className="sorting">
                    <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.totalInvoice')}</span>
                  </div>
                </th>
                {isAllowed('downloadIndividualInvoice') && (
                  <th>
                    <div className="sorting">
                      <span>{isLoader ? <Skeleton height={15} width={100} /> : t('addBillingInvoice.downloadInvoice')}</span>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {isLoader ? (
                <tr>
                  <td colSpan={18} className="border-0">
                    <Skeleton height={15} />
                  </td>
                </tr>
              ) : _.isEmpty(invoiceList) ? (
                <tr>
                  <td colSpan={9} className="border-0">
                    <div className="empty-data-block">{t('addBillingInvoice.noInvoice')}</div>
                  </td>
                </tr>
              ) : (
                !(isLoader || _.isUndefined(isLoader)) &&
                _.map(invoiceList, (item, key) => {
                  let gst;
                  if (item.invoice.gst_type === 'SGST') {
                    gst = roundOfDigit(item.invoice.gst / 2, 2);
                  } else {
                    gst = '-';
                  }

                  let igst;
                  if (item.invoice.gst_type === 'IGST') {
                    igst = parseFloat(roundOfDigit(item.invoice.gst, 2));
                    console.log("igst: ", igst)
                  } else {
                    igst = '-';
                  }

                  const unitConsumed = isNaN(_.get(item, 'meterstop') - _.get(item, 'meterstart') / 1000)
                    ? '-'
                    : roundOfDigit((_.get(item, 'meterstop') - _.get(item, 'meterstart')) / 1000, 3);

                  const serviceCharge = _.get(item, 'invoice.service_charge') ?? _.get(item, 'invoice.subtotal');
                  const taxableValue = serviceCharge.toFixed(2) - _.get(item, 'invoice.chargecoins_used', 0);

                  const serial_num = limit * (page - 1) + key;
                  const loading = downloading[item._id ? item._id : item.id];
                  return (
                    <tr key={`invoice-${serial_num}`}>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : serial_num + 1}</td>
                      <td>
                        {isLoader ? (
                          <Skeleton height={15} width={100} />
                        ) : item.is_ocpi_based_booking && item?.ocpi_user !== undefined && item?.ocpi_user !== null ? (
                          item?.ocpi_user?.details?.user_name || 'Guest User'
                        ) : (
                          _.get(item, 'customer_user_booked.name', 'Guest User')
                        )}
                      </td>
                      <td>
                        {isLoader ? (
                          <Skeleton height={15} width={100} />
                        ) : (
                          <>
                            {' '}
                            +{_.get(item, 'customer_user_booked.country_code', '')}
                            {_.get(item, 'customer_user_booked.phone', 'NA')}
                          </>
                        )}
                      </td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'customer_user_booked.email', 'NA')}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.gst_used', '')}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'charger.charging_station.state', '')}</td>
                      <td>
                        {isLoader ? (
                          <Skeleton height={15} width={100} />
                        ) : item.is_ocpi_based_booking && item?.ocpi_user !== undefined && item?.ocpi_user !== null ? (
                          item?.ocpi_user?.details?.user_gstin || ''
                        ) : (
                          _.get(item, 'customer_gst', '')
                        )}
                      </td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.invoice_no', '-')}</td>
                      <td>
                        {isLoader ? <Skeleton height={15} width={100} /> : moment(_.get(item, 'invoice.createdAt', '-')).format('DD/MM/YYYY H:mm:ss')}
                      </td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : formatNumber(unitConsumed)}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : typeof serviceCharge === 'number' ? serviceCharge.toFixed(2) : ''}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : typeof taxableValue === 'number' ? taxableValue.toFixed(2) : ''}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : formatNumber(gst)}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : formatNumber(gst)}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : formatNumber(igst)}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : _.get(item, 'invoice.chargecoins_used', '-')}</td>
                      <td>{isLoader ? <Skeleton height={15} width={100} /> : roundOfDigit(_.get(item, 'invoice.cashback', '-'), 2)}</td>

                      <td>
                        {isLoader ? (
                          <Skeleton height={15} width={100} />
                        ) : igst === '-' ? (
                          (taxableValue + gst * 2).toFixed(2)
                        ) : (
                          (taxableValue + igst).toFixed(2)
                        )}
                      </td>
                      {isAllowed('downloadIndividualInvoice') && (
                        <td>
                          {isLoader ? (
                            <Skeleton height={15} width={100} />
                          ) : (
                            <>
                              {loading ? (
                                <div className="loader--block">
                                  <Spinner as="span" animation="border" size="sm" role="status" />
                                  <span className="ps-2">{t('placeHolder.loading')}</span>
                                </div>
                              ) : (
                                <Button
                                  disabled={item?.invoice?.einvoice_generated === false || unitConsumed === 0}
                                  onClick={() => singleInvoiceDownload(item)}
                                >
                                  {t('button.downloadInvoice')}
                                </Button>
                              )}
                            </>
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </SkeletonTheme>
    </div>
  );
};
InvoiceInfo.propTypes = {
  startingDate: PropTypes.string,
  endingDate: PropTypes.string,
  todayDateData: PropTypes.string,
  weeklyDateData: PropTypes.string,
  monthlyDateData: PropTypes.string,
};
export default InvoiceInfo;

export const InvoiceTable = () => {
  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const invoiceDetail = useSelector((state) => state.chargerBooking.chargerBookingDetail);
  const { t } = useTranslation();
  const getInvoiceById = useCallback((id) => {
    dispatch({ type: GET_CHARGER_BOOKING, payload: id });
  });

  useEffect(() => {
    getInvoiceById(invoiceId);
  }, []);

  const downloadInvoice = () => {
    //download Invoice
    let content = document.getElementById('downloadInvoice');
    let print = document.getElementById('invoicePrint').contentWindow;
    print.document.open('font-style:normal');
    print.document.write(content.innerHTML);
    print.document.close();
    print.focus();
    print.print();
  };
  //To find which connector is available
  const connectorData = _.find(invoiceDetail && invoiceDetail.charger ? _.get(invoiceDetail, 'charger.plugs', '') : '', {
    connector_id: invoiceDetail && invoiceDetail.connectorId ? invoiceDetail.connectorId : '',
  });

  //calculation of total Amount.
  const totalAmount = _.get(invoiceDetail, 'invoice.amount', '-') - _.get(invoiceDetail, 'invoice.cashback');

  //calculation of unit Consumption.
  const unitConsumed = isNaN(_.get(invoiceDetail, 'meterstop') - _.get(invoiceDetail, 'meterstart') / 1000)
    ? '-'
    : roundOfDigit((_.get(invoiceDetail, 'meterstop') - _.get(invoiceDetail, 'meterstart')) / 1000, 3);

  //calculation of pricePerUnit Consumption.
  const pricePerUnit = unitConsumed
    ? isNaN(parseFloat(_.get(invoiceDetail, 'invoice.subtotal') / unitConsumed).toFixed(2))
      ? '-'
      : parseFloat(_.get(invoiceDetail, 'invoice.subtotal') / unitConsumed).toFixed(2)
    : '-';

  //Functionality of convert difference of two date to time
  const bookingStart = moment(_.get(invoiceDetail, 'booking_start', '-')).format('DD/MM/YYYY h:mm A');
  const bookingStop = moment(_.get(invoiceDetail, 'booking_stop', '-')).format('DD/MM/YYYY h:mm A');
  const chargingDurationHour = moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'h');
  const chargingDurationMinute = moment(bookingStop, 'DD/MM/YYYY h:mm A').diff(moment(bookingStart, 'DD/MM/YYYY h:mm A'), 'm');

  return (
    <div className="invoice-page_main">
      <div className="print--btn">
        <Button onClick={downloadInvoice} className="px-5">
          {t('button.print')}
        </Button>
      </div>
      <iframe title="Invoice" id="invoicePrint" className="iframe-class" />
      <Card className="invoice-page--wrapper scrollable">
        <div id="downloadInvoice">
          <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
            <tbody>
              <tr>
                <td align="center">
                  <div className="logo--img">
                    <img style={{ height: 100, width: 200 }} src={CZLogo} alt="chargeZone" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={4} style={{ noshade: '' }} color="#ee2027" />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: 'center' }}>
                  <h2 style={{ marginBottom: 0 }}>
                    Thank you for Charging with us, <font color="#ee2027">{_.get(invoiceDetail, 'customer_user_booked.name', 'Guest User')}!</font>
                  </h2>
                </td>
              </tr>
              <tr>
                <td height={60}>&nbsp;</td>
              </tr>
              <tr>
                <td align="left">
                  <table width="40%" border={1} style={{ bordercolor: '#000000' }} cellSpacing={0} cellPadding={3}>
                    <tbody>
                      <tr>
                        <th width="51%" align="left" style={{ backgroundColor: '#cccccc' }}>
                          {t('addBillingInvoice.invoiceNo')}
                        </th>
                        <td width="49%" align="right">
                          {_.get(invoiceDetail, 'invoice.id', '-')}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" style={{ backgroundColor: '#cccccc' }}>
                          {t('addBillingInvoice.invoiceDate')}
                        </th>
                        <td align="right">{moment(_.get(invoiceDetail, 'booking_start', '-')).format('DD/MM/YYYY')}</td>
                      </tr>
                      <tr>
                        <th align="left" style={{ backgroundColor: '#cccccc' }}>
                          {t('placeHolder.bookingID')}
                        </th>
                        <td align="right"> {_.get(invoiceDetail, 'id', '-')}</td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="100%" border={0} cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td width="50%" valign="top">
                          <table width="98%" border={0} cellSpacing={0} cellPadding={3}>
                            <tbody>
                              <tr>
                                <th align="left" style={{ backgroundColor: '#cccccc' }}>
                                  {t('addBillingInvoice.invoiceTo')}
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={3}>
                                    <tbody>
                                      <tr>
                                        <td width="35%">{t('placeHolder.name')}</td>
                                        <td width="65%">{_.get(invoiceDetail, 'customer_user_booked.name', 'Guest User')}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('placeHolder.contactNo')}</td>
                                        <td>
                                          {`+${_.get(invoiceDetail, 'customer_user_booked.country_code', '-')}` +
                                            ' ' +
                                            _.get(invoiceDetail, 'customer_user_booked.phone', '-')}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('placeHolder.emailId')}</td>
                                        <td>{_.get(invoiceDetail, 'customer_user_booked.email', '-')}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.evModel')}</td>
                                        <td>{_.get(invoiceDetail, 'vehicle.make', '-') + ' ' + `(${_.get(invoiceDetail, 'vehicle.model', '-')})`}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.evrn')}</td>
                                        <td>{_.get(invoiceDetail, 'vehicle.vin_num', '-')}</td>
                                      </tr>
                                      {invoiceDetail.customer_gst && (
                                        <tr>
                                          <td>{t('downloadInvoice.customerGst')}</td>
                                          <td>{_.get(invoiceDetail, 'customer_gst', '-')}</td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td width="50%" valign="top">
                          <table width="98%" border={0} cellSpacing={0} cellPadding={3}>
                            <tbody>
                              <tr>
                                <th align="left" style={{ backgroundColor: '#cccccc' }}>
                                  {t('downloadInvoice.stationInfo')}
                                </th>
                              </tr>
                              <tr>
                                <td>
                                  <table width="100%" border={0} cellSpacing={0} cellPadding={3}>
                                    <tbody>
                                      <tr>
                                        <td width="40%"> {t('downloadInvoice.stationName')}</td>
                                        <td width="60%">{_.get(invoiceDetail, 'charger.charging_station.name', '-')}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.chargeSpot')}</td>
                                        <td>
                                          {_.get(invoiceDetail, 'charger.charger_id', '') +
                                            ' ' +
                                            (connectorData ? `(${_.get(connectorData, 'name')})` : '')}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.chargingDateTime')}</td>
                                        <td>{moment(_.get(invoiceDetail, 'booking_start', '-')).format('DD/MM/YYYY h:mm:ss')}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.chargingDuration')}</td>
                                        <td>{`${chargingDurationHour}:${chargingDurationMinute}`}</td>
                                      </tr>
                                      <tr>
                                        <td>{t('downloadInvoice.gstNo')}</td>
                                        <td>{_.get(invoiceDetail, 'invoice.gst_used', '-')}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  <table width="100%" cellSpacing={0} cellPadding={3} border={0} style={{ borderColor: '#000000' }}>
                    <tbody align="center">
                      <tr>
                        <th width="18%" style={{ backgroundColor: '#cccccc' }}>
                          {t('downloadInvoice.description')}
                        </th>
                        <th width="18%" style={{ backgroundColor: '#cccccc' }}>
                          {t('downloadInvoice.pricePerUnit')}
                        </th>
                        <th width="18%" style={{ backgroundColor: '#cccccc' }}>
                          {t('downloadInvoice.unitConsumed')}
                        </th>
                        <th width="18%" style={{ backgroundColor: '#cccccc' }}>
                          {t('downloadInvoice.amount')}
                        </th>
                      </tr>
                      <tr>
                        <td>
                          <strong>{t('addBillingInvoice.serviceCharge')}</strong>
                        </td>
                        <td align="center">
                          <strong>{pricePerUnit}</strong>
                        </td>
                        <td align="center">
                          <strong>{unitConsumed}</strong>
                        </td>
                        <td align="center">
                          <strong>{roundOfDigit(_.get(invoiceDetail, 'invoice.subtotal', '-'), 2)}</strong>
                        </td>
                      </tr>
                      {!_.isEmpty(invoiceDetail, 'invoice.cashback') && _.get(invoiceDetail, 'is_rfid_based_booking', false) && (
                        <tr>
                          <td>
                            <strong>
                              {t('downloadInvoice.cashback')} (
                              {_.get(invoiceDetail, 'invoice.cashback_percent')
                                ? `${roundOfDigit(_.get(invoiceDetail, 'invoice.cashback_percent'), 2)}%`
                                : '-'}
                              )
                            </strong>
                          </td>
                          <td align="center">&nbsp;</td>
                          <td align="center">&nbsp;</td>
                          <td align="center">
                            <strong>
                              {_.get(invoiceDetail, 'invoice.cashback') ? `-${roundOfDigit(_.get(invoiceDetail, 'invoice.cashback'), 2)}` : '-'}
                            </strong>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <strong>{t('downloadInvoice.cgst')}</strong>
                        </td>
                        <td align="center">&nbsp;</td>
                        <td align="center">&nbsp;</td>
                        <td align="center">
                          <strong>
                            {isNaN(_.get(invoiceDetail, 'invoice.gst') / 2) ? '-' : roundOfDigit(_.get(invoiceDetail, 'invoice.gst') / 2, 2)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>{t('downloadInvoice.sgst')}</strong>
                        </td>
                        <td align="center">&nbsp;</td>
                        <td align="center">&nbsp;</td>
                        <td align="center">
                          <strong>
                            {isNaN(_.get(invoiceDetail, 'invoice.gst') / 2) ? '-' : roundOfDigit(_.get(invoiceDetail, 'invoice.gst') / 2, 2)}
                          </strong>
                        </td>
                      </tr>
                      <tr>
                        <td />
                        <td />
                        <td />
                        <td>
                          <hr />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3} align="right">
                          <strong>{t('downloadInvoice.totalPayableAmount')}</strong>
                        </td>
                        <td align="center">
                          <strong>
                            {_.get(invoiceDetail, 'invoice.cashback')
                              ? roundOfDigit(totalAmount, 2)
                              : parseFloat(_.get(invoiceDetail, 'invoice.amount', '-')).toFixed(2)}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td height={30}>&nbsp;</td>
              </tr>
              <tr>
                <td align="center">
                  <h1 style={{ marginBottom: 0 }}>
                    <font color="#ee2027">
                      {t('downloadInvoice.totalAmountPaid')}{' '}
                      {_.get(invoiceDetail, 'invoice.cashback')
                        ? roundOfDigit(totalAmount, 2)
                        : parseFloat(_.get(invoiceDetail, 'invoice.amount', '-')).toFixed(2)}
                    </font>
                  </h1>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  For any dispute, please email us at <a href="mailto:support@chargezone.com"> support@chargezone.com</a> &nbsp;
                  <strong>or</strong> Call us at Toll Free Number <strong>1800-121-2025.</strong> For more details, please visit us at{' '}
                  <a href="https://chargezone.com/">
                    <strong> {t('downloadInvoice.siteurl')}</strong>
                  </a>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td align="center" style={{ fontSize: 18 }}>
                  <strong>{t('downloadInvoice.tecSo')}</strong>
                </td>
              </tr>
              <tr>
                <td>
                  <hr size={4} style={{ noshade: '' }} color="#ee2027" />
                </td>
              </tr>
              <tr>
                <td align="center">{t('downloadInvoice.address')}</td>
              </tr>
              <tr>
                <td align="center">{t('downloadInvoice.contactNo')}</td>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};
