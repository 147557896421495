export const FETCH_CUSTOMER = 'FETCH_CUSTOMER';
export const FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS';
export const FETCH_CUSTOMER_FAIL = 'FETCH_CUSTOMER_FAIL';

export const FETCH_WIDGETS = 'FETCH_WIDGETS';
export const FETCH_WIDGETS_SUCCESS = 'FETCH_WIDGETS_SUCCESS';
export const FETCH_WIDGETS_FAIL = 'FETCH_WIDGETS_FAIL';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_FAIL = 'GET_CUSTOMER_FAIL';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = ' UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAIL = ' DELETE_CUSTOMER_FAIL';

export const WALLET_TRANSACTION = 'WALLET_TRANSACTION';
export const WALLET_TRANSACTION_SUCCESS = 'WALLET_TRANSACTION_SUCCESS';
export const WALLET_TRANSACTION_FAIL = 'WALLET_TRANSACTION_FAIL';

export const FETCH_CUSTOMER_PROFILE = 'FETCH_CUSTOMER_PROFILE';
export const FETCH_CUSTOMER_PROFILE_SUCCESS = 'FETCH_CUSTOMER_PROFILE_SUCCESS';
export const FETCH_CUSTOMER_PROFILE_FAIL = 'FETCH_CUSTOMER_PROFILE_FAIL';

export const FETCH_CUSTOMER_BOOKING = 'FETCH_CUSTOMER_BOOKING';
export const FETCH_CUSTOMER_BOOKING_SUCCESS = 'FETCH_CUSTOMER_BOOKING_SUCCESS';
export const FETCH_CUSTOMER_BOOKING_FAIL = 'FETCH_CUSTOMER_BOOKING_FAIL';

export const FETCH_CUSTOMER_TRANSACTION = 'FETCH_CUSTOMER_TRANSACTION';
export const FETCH_CUSTOMER_TRANSACTION_SUCCESS = 'FETCH_CUSTOMER_TRANSACTION_SUCCESS';
export const FETCH_CUSTOMER_TRANSACTION_FAIL = 'FETCH_CUSTOMER_TRANSACTION_FAIL';

export const DOWNLOAD_CUSTOMER_BOOKING = 'DOWNLOAD_CUSTOMER_BOOKING';
export const DOWNLOAD_CUSTOMER_BOOKING_SUCCESS = 'DOWNLOAD_CUSTOMER_BOOKING_SUCCESS';
export const DOWNLOAD_CUSTOMER_BOOKING_FAIL = 'DOWNLOAD_CUSTOMER_BOOKING_FAIL';

export const FETCH_CHARGE_COIN_TRANSACTION = 'FETCH_CHARGE_COIN_TRANSACTION';
export const FETCH_CHARGE_COIN_TRANSACTION_SUCCESS = 'FETCH_CHARGE_COIN_TRANSACTION_SUCCESS';
export const FETCH_CHARGE_COIN_TRANSACTION_FAIL = 'FETCH_CHARGE_COIN_TRANSACTION_FAIL';

export const DOWNLOAD_CUSTOMER_TRANSACTION = 'DOWNLOAD_CUSTOMER_TRANSACTION';
export const DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS = 'DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS';
export const DOWNLOAD_CUSTOMER_TRANSACTION_FAIL = 'DOWNLOAD_CUSTOMER_TRANSACTION_FAIL';

export const SYNC_WALLET = 'SYNC_WALLET';
export const SYNC_WALLET_SUCCESS = 'SYNC_WALLET_SUCCESS';
export const SYNC_WALLET_FAIL = 'SYNC_WALLET_FAIL';

export const CALCULATE_CUSTOMER_BOOKING = 'CALCULATE_CUSTOMER_BOOKING';
export const CALCULATE_CUSTOMER_BOOKING_SUCCESS = 'CALCULATE_CUSTOMER_BOOKING_SUCCESS';
export const CALCULATE_CUSTOMER_BOOKING_FAIL = 'CALCULATE_CUSTOMER_BOOKING_FAIL';

export const CREATE_CUSTOMER_BOOKING = 'CREATE_CUSTOMER_BOOKING';
export const CREATE_CUSTOMER_BOOKING_SUCCESS = 'CREATE_CUSTOMER_BOOKING_SUCCESS';
export const CREATE_CUSTOMER_BOOKING_FAIL = 'CREATE_CUSTOMER_BOOKING_FAIL';

export const RESCHEDULE_CUSTOMER_BOOKING = 'RESCHEDULE_CUSTOMER_BOOKING';
export const RESCHEDULE_CUSTOMER_BOOKING_SUCCESS = 'RESCHEDULE_CUSTOMER_BOOKING_SUCCESS';
export const RESCHEDULE_CUSTOMER_BOOKING_FAIL = 'RESCHEDULE_CUSTOMER_BOOKING_FAIL';

export const CANCEL_CUSTOMER_BOOKING = 'CANCEL_CUSTOMER_BOOKING';
export const CANCEL_CUSTOMER_BOOKING_SUCCESS = 'CANCEL_CUSTOMER_BOOKING_SUCCESS';
export const CANCEL_CUSTOMER_BOOKING_FAIL = 'CANCEL_CUSTOMER_BOOKING_FAIL';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';

export const UPDATE_CUSTOMER_VEHICLE = 'UPDATE_CUSTOMER_VEHICLE';
export const UPDATE_CUSTOMER_VEHICLE_SUCCESS = ' UPDATE_CUSTOMER_VEHICLE_SUCCESS';
export const UPDATE_CUSTOMER_VEHICLE_FAIL = 'UPDATE_CUSTOMER_VEHICLE_FAIL';