import { call, put, takeLatest } from 'redux-saga/effects';
import { API } from '../api';
import {
  FETCH_CUSTOMER,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAIL,
  FETCH_WIDGETS,
  FETCH_WIDGETS_SUCCESS,
  FETCH_WIDGETS_FAIL,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_CUSTOMER_FAIL,
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_FAIL,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAIL,
  DELETE_CUSTOMER,
  DELETE_CUSTOMER_SUCCESS,
  DELETE_CUSTOMER_FAIL,
  WALLET_TRANSACTION,
  WALLET_TRANSACTION_SUCCESS,
  WALLET_TRANSACTION_FAIL,
  FETCH_CUSTOMER_TRANSACTION,
  FETCH_CUSTOMER_TRANSACTION_SUCCESS,
  FETCH_CUSTOMER_TRANSACTION_FAIL,
  FETCH_CUSTOMER_BOOKING,
  FETCH_CUSTOMER_BOOKING_SUCCESS,
  FETCH_CUSTOMER_BOOKING_FAIL,
  FETCH_CUSTOMER_PROFILE,
  FETCH_CUSTOMER_PROFILE_SUCCESS,
  FETCH_CUSTOMER_PROFILE_FAIL,
  DOWNLOAD_CUSTOMER_BOOKING,
  DOWNLOAD_CUSTOMER_BOOKING_SUCCESS,
  DOWNLOAD_CUSTOMER_BOOKING_FAIL,
  FETCH_CHARGE_COIN_TRANSACTION,
  FETCH_CHARGE_COIN_TRANSACTION_SUCCESS,
  FETCH_CHARGE_COIN_TRANSACTION_FAIL,
  DOWNLOAD_CUSTOMER_TRANSACTION,
  DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS,
  DOWNLOAD_CUSTOMER_TRANSACTION_FAIL,
  SYNC_WALLET,
  SYNC_WALLET_SUCCESS,
  SYNC_WALLET_FAIL,
  CALCULATE_CUSTOMER_BOOKING,
  CALCULATE_CUSTOMER_BOOKING_SUCCESS,
  CALCULATE_CUSTOMER_BOOKING_FAIL,
  CREATE_CUSTOMER_BOOKING,
  CREATE_CUSTOMER_BOOKING_SUCCESS,
  CREATE_CUSTOMER_BOOKING_FAIL,
  RESCHEDULE_CUSTOMER_BOOKING_SUCCESS,
  RESCHEDULE_CUSTOMER_BOOKING_FAIL,
  RESCHEDULE_CUSTOMER_BOOKING,
  CANCEL_CUSTOMER_BOOKING_SUCCESS,
  CANCEL_CUSTOMER_BOOKING_FAIL,
  CANCEL_CUSTOMER_BOOKING,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAIL,
  FETCH_ROLES,
  UPDATE_CUSTOMER_VEHICLE,
  UPDATE_CUSTOMER_VEHICLE_SUCCESS,
  UPDATE_CUSTOMER_VEHICLE_FAIL,
} from 'actions/customer';

function* fetchCustomer(action) {
  try {
    const response = yield call(API.getCustomers, action.payload);
    yield put({ type: FETCH_CUSTOMER_SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* fetchWidgets(action) {
  try {
    const response = yield call(API.getWidgets, action.payload);
    yield put({ type: FETCH_WIDGETS_SUCCESS, payload: response });
    action.cs && action.cs(response);
  } catch (e) {
    yield put({ type: FETCH_WIDGETS_FAIL, payload: { error: e } });
  }
}

function* getCustomerById(action) {
  try {
    const response = yield call(API.getUserById, action.payload);
    yield put({ type: GET_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: GET_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* createCustomer(action) {
  try {
    const response = yield call(API.addUser, action.payload);
    yield put({ type: CREATE_CUSTOMER_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: CREATE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* createWalletTransaction(action) {
  try {
    const response = yield call(API.walletTransaction, action.payload);
    yield put({ type: WALLET_TRANSACTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: WALLET_TRANSACTION_FAIL, payload: { error: e } });
  }
}

function* fetchCustomerProfile(action) {
  try {
    const response = yield call(API.getCustomerProfile, action.payload);
    yield put({ type: FETCH_CUSTOMER_PROFILE_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_PROFILE_FAIL, payload: { error: e } });
  }
}

function* fetchCustomerBooking(action) {
  try {
    const response = yield call(API.getCustomerBooking, action.payload);
    yield put({ type: FETCH_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* fetchCustomerTransaction(action) {
  try {
    const response = yield call(API.getCustomerTransaction, action.payload);
    yield put({ type: FETCH_CUSTOMER_TRANSACTION_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CUSTOMER_TRANSACTION_FAIL, payload: { error: e } });
  }
}

function* updateCustomer(action) {
  try {
    const response = yield call(API.updateCustomer, action.payload);
    yield put({ type: UPDATE_CUSTOMER_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* deleteCustomer(action) {
  try {
    yield call(API.deleteUser, action.payload);
    yield put({ type: DELETE_CUSTOMER_SUCCESS, payload: action.payload });
  } catch (e) {
    yield put({ type: DELETE_CUSTOMER_FAIL, payload: { error: e } });
  }
}

function* downloadCustomerBooking(action) {
  try {
    const response = yield call(API.downloadCustomerBooking, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* fetchChargeCoinTransaction(action) {
  try {
    const response = yield call(API.getAllChargeCoinTransaction, action.payload);
    yield put({ type: FETCH_CHARGE_COIN_TRANSACTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_CHARGE_COIN_TRANSACTION_FAIL, payload: { error: e } });
  }
}

function* downloadCustomerTransactions(action) {
  try {
    const response = yield call(API.downloadCustomerTransactions, action.payload);
    yield put({ type: DOWNLOAD_CUSTOMER_TRANSACTION_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: DOWNLOAD_CUSTOMER_TRANSACTION_FAIL, payload: { error: e } });
    action.cb && action.cb(e);
  }
}

function* syncWallet(action) {
  try {
    const response = yield call(API.syncWallet, action.payload);
    yield put({ type: SYNC_WALLET_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: SYNC_WALLET_FAIL, payload: { error: e } });
  }
}

function* calculateCustomerBooking(action) {
  try {
    const response = yield call(API.calculateCustomerBooking, action.payload);
    yield put({ type: CALCULATE_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CALCULATE_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* createCustomerBooging(action) {
  try {
    const response = yield call(API.createCustomerBooking, action.payload);
    yield put({ type: CREATE_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CREATE_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* rescheduleCustomerBooking(action) {
  try {
    const response = yield call(API.rescheduleCustomerBooking, action.payload);
    yield put({ type: RESCHEDULE_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: RESCHEDULE_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* cancelCustomerBooging(action) {
  try {
    const response = yield call(API.cancelCustomerBooking, action.payload);
    yield put({ type: CANCEL_CUSTOMER_BOOKING_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: CANCEL_CUSTOMER_BOOKING_FAIL, payload: { error: e } });
  }
}

function* getRoles(action) {
  try {
    const response = yield call(API.getRoles, action.payload);
    console.log(response);
    yield put({ type: FETCH_ROLES_SUCCESS, payload: response });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: FETCH_ROLES_FAIL, payload: { error: e } });
  }
}

function* updateCustomerVehicle(action) {
  try {
    const response = yield call(API.updateCustomerVehicle, action.payload);
    yield put({ type: UPDATE_CUSTOMER_VEHICLE_SUCCESS, payload: response.data });
    action.cb && action.cb(response);
  } catch (e) {
    yield put({ type: UPDATE_CUSTOMER_VEHICLE_FAIL, payload: { error: e } });
  }
}

function* customer() {
  yield takeLatest(FETCH_CUSTOMER, fetchCustomer);
  yield takeLatest(FETCH_WIDGETS, fetchWidgets);
  yield takeLatest(GET_CUSTOMER, getCustomerById);
  yield takeLatest(CREATE_CUSTOMER, createCustomer);
  yield takeLatest(UPDATE_CUSTOMER, updateCustomer);
  yield takeLatest(DELETE_CUSTOMER, deleteCustomer);
  yield takeLatest(WALLET_TRANSACTION, createWalletTransaction);
  yield takeLatest(FETCH_CUSTOMER_TRANSACTION, fetchCustomerTransaction);
  yield takeLatest(FETCH_CUSTOMER_BOOKING, fetchCustomerBooking);
  yield takeLatest(FETCH_CUSTOMER_PROFILE, fetchCustomerProfile);
  yield takeLatest(DOWNLOAD_CUSTOMER_BOOKING, downloadCustomerBooking);
  yield takeLatest(FETCH_CHARGE_COIN_TRANSACTION, fetchChargeCoinTransaction);
  yield takeLatest(DOWNLOAD_CUSTOMER_TRANSACTION, downloadCustomerTransactions);
  yield takeLatest(SYNC_WALLET, syncWallet);
  yield takeLatest(CALCULATE_CUSTOMER_BOOKING, calculateCustomerBooking);
  yield takeLatest(CREATE_CUSTOMER_BOOKING, createCustomerBooging);
  yield takeLatest(RESCHEDULE_CUSTOMER_BOOKING, rescheduleCustomerBooking);
  yield takeLatest(CANCEL_CUSTOMER_BOOKING, cancelCustomerBooging);
  yield takeLatest(FETCH_ROLES, getRoles);
  yield takeLatest(UPDATE_CUSTOMER_VEHICLE, updateCustomerVehicle);
}

export default customer;
